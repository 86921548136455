/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    ul: "ul",
    li: "li",
    h3: "h3"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "resound-vea-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resound-vea-hearing-aid",
    "aria-label": "resound vea hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ReSound Vea Hearing Aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You shouldn’t have to sacrifice your hearing for the sake of your bank account. Thanks to the ReSound Vea, you won’t need to. The ReSound Vea hearing aid is an easy to use ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing device"), " that packs ", React.createElement(_components.a, {
    href: "/hearing-aids/technology",
    className: "c-md-a"
  }, "a whole lot of innovative technology"), " into one small package."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Thanks to features such as feedback management, noise reduction, and adaptive directionality, the ReSound Vea hearing aid provides a listening experience so natural, it’s easy to forget you’re even wearing it. Durability is also the name of the game with the ReSound Vea, so you can keep on living your best life."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ReSound Vea line of hearing aids is home to six different models with three different levels of technology, so there’s something in the Vea family for everyone. Get ready to meet the hearing aid that can keep you hearing well, day in and day out."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "in-brief",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#in-brief",
    "aria-label": "in brief permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "In Brief"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Advanced technology is housed inside an easy-to-use casing"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Aventa, AutoFit, and Speedlink technologies make the Vea easy to fit to any ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "iSolate nanotech coating provides a durable and long-lasting protective cover for everyday use"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Six models and three technology levels mean there’s a Vea hearing aid for everyone"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "NoiseTracker II helps remove background noise for a better listening environment"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "WhistleControl helps eliminate feedback without affecting sound quality"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Adaptive Directionality and SoftSwitching provide a more natural hearing experience"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "three-levels-of-intuitive-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#three-levels-of-intuitive-technology",
    "aria-label": "three levels of intuitive technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Three Levels Of Intuitive Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The ReSound Vea is a family of hearing aids designed to meet a wide range of hearing loss needs and budget levels. With three levels of technology, the Vea family of hearing aids is one of the most well-rounded on the market."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "the-resound-vea-1",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-resound-vea-1",
    "aria-label": "the resound vea 1 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The ReSound Vea 1"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Is designed for folks who spend most of their time in relatively calm hearing environments, such as the home, where amplification and noise reduction are top priorities. For those of us who go out into public environments, such as the shopping mall or golf course,"), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "the-resound-vea-2",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-resound-vea-2",
    "aria-label": "the resound vea 2 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The ReSound Vea 2"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Features improved directionality technology and extra noise reduction for a better listening experience."), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "resound-vea-3",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#resound-vea-3",
    "aria-label": "resound vea 3 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "ReSound Vea 3"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Is specifically designed for the active lifestyle, where speech understanding and background noise reduction is critical in a difficult hearing environment. Whatever your needs, there’s a ReSound Vea technology level out there to help!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "options-to-meet-every-need",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#options-to-meet-every-need",
    "aria-label": "options to meet every need permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Options To Meet Every Need"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Whether you enjoy the ease of use of a ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte",
    className: "c-md-a"
  }, "behind-the-ear hearing aid"), " or you like the discreetness of a ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite",
    className: "c-md-a"
  }, "custom in-the-ear hearing aid"), ", Vea is ready to improve your listening experience. Six different models in the ReSound Vea hearing aid family means it’s easy to find a device that matches your pace of life."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "durability-for-life",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#durability-for-life",
    "aria-label": "durability for life permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Durability For Life"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The last thing you want when you get your new hearing aids is for them to break. Thanks to the ReSound Vea’s iSolate nanotech coating, you can wave goodbye to annoying repair shop visits and say hello to a seamless listening experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The iSolate nanotech coating provides unparalleled moisture protection and increased durability throughout the lifetime of a Vea hearing aid. The iSolate nanotech coating protects your hearing aid from any moisture or dust that tries to get in. The result? Better sound quality and less hassle in your day to day life. What could be better?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you’ve ever worn your hearing aids in a windy environment, you know how difficult it can be to hold a conversation when the wind picks up. Thanks to the ReSound Vea’s Integrated Wind Noise management system, ", React.createElement(_components.a, {
    href: "/hearing-loss/everyday-life",
    className: "c-md-a"
  }, "you can keep living your best life"), ", even when the weather turns south."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "adaptive-directionality-for-a-natural-listening-experience",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#adaptive-directionality-for-a-natural-listening-experience",
    "aria-label": "adaptive directionality for a natural listening experience permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Adaptive Directionality For A Natural Listening Experience"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "So many hearing aids promise to provide a fantastic listening experience, but their clunky technology makes you feel like you’re on the inside of a fishbowl, not at a fancy dinner with your friends. Thankfully, the Vea’s Adaptive Directionality technology allows it to quickly and seamlessly switch back and forth between different microphones as a conversation unfolds to provide a more natural listening experience. That’s something to smile about."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "eliminate-feedback-and-background-noise-from-your-life",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#eliminate-feedback-and-background-noise-from-your-life",
    "aria-label": "eliminate feedback and background noise from your life permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Eliminate Feedback And Background Noise From Your Life"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Oh, the dreaded feedback noise. As any hearing aid user knows, feedback can quickly and easily ruin a wonderful moment. That’s why ReSound packed the Vea full of Dual Stabilizer II DFS technology that can reduce feedback in every situation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sometimes, our hearing aids are just too good at their jobs. They can amplify sounds and direct them right into our ears, but when there’s too much background noise, all of this amplification can hurt more than it helps. With ReSound Vea’s NoiseTracker II, however, you can say bye-bye to annoying background noise without reducing audio quality for better conversations in the noisiest of places."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Just because you wear hearing aids doesn’t mean you have to sacrifice your style. The ReSound Vea line of hearing aids is easily customizable with a variety of different colors so you don’t need to compromise on looks just for a better listening experience."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "were-here-to-help",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#were-here-to-help",
    "aria-label": "were here to help permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "We’re Here to Help"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Are you overwhelmed with all of the different hearing aids options available to you? Do you need guidance to choose the hearing aid that’s right for your needs and style? We’re here to help. Contact us today to speak with one of our hearing experts and find out if the ReSound Vea is the right hearing aid for you. Simply call us or click the “Get started” button below to see if you qualify for a 45 day risk-free trial."), "\n", React.createElement(LandingPageCta, {
    copy: "TRY THE RESOUND VEA NOW",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
